/* 
This file is needed becauce the default behavior of having the body set to `position: fixed;` is very helpful in fullscreen mode
while making it almost impossible to implement any sort of scrolling behaviour on iOS devices when not in fullscreen mode.

Since a css-in-js style is designed not to overrule styles, i couldn't find a proper way to overrule the style applied to body and html
fount in `base-styles.css` without creating a new stylesheet. This style sheet is only referenced from InstallationFlow.jsx 
- Jens H

*/

html,
body {
  position: relative !important;
  overflow: auto !important;
}
