.ant-select.withValue .ant-select-selector {
  background: #103447;
  color: #fff;
}

.ant-select.withValue .ant-select-clear {
  opacity: 1;
  background: transparent;
  color: #fff;
}

.contact-it-support-modal .ant-modal-header {
  padding: 0;
}
