.page-forward-enter,
.page-forward-enter-active,
.page-forward-exit,
.page-forward-exit-active,
.page-backward-enter,
.page-backward-enter-active,
.page-backward-exit,
.page-backward-exit-active {
  transition-delay: 50ms;
  transition: transform 320ms ease, opacity 120ms ease;
  position: absolute;
  top: 0;
  will-change: auto;
}

.page-forward-enter,
.page-forward-enter-active {
  z-index: 4;
}

.page-forward-exit,
.page-forward-exit-active {
  z-index: 3;
}

@media screen and (max-width: 1024px) {
  .page-forward-enter {
    transform: translate3d(100%, 0, 0);
  }

  .page-forward-enter-active {
    transform: translate3d(0%, 0, 0);
  }

  .page-forward-exit {
    transform: translate3d(0%, 0, 0);
  }

  .page-forward-exit-active {
    transform: translate3d(-40%, 0, 0);
  }
}

@media screen and (min-width: 1025px) {
  .page-forward-enter {
    transform: translateX(100px);
    opacity: 0;
  }

  .page-forward-enter-active {
    transform: translateX(0px);
    opacity: 1;
  }
}

/* Page transition backward */

.page-backward-enter,
.page-backward-enter-active {
  z-index: 3;
}

.page-backward-exit,
.page-backward-exit-active {
  z-index: 4;
}

@media screen and (max-width: 1024px) {
  .page-backward-enter {
    transform: translate3d(-40%, 0, 0);
  }

  .page-backward-enter-active {
    transform: translate3d(0%, 0, 0);
  }

  .page-backward-exit {
    transform: translate3d(0%, 0px, 0);
  }

  .page-backward-exit-active {
    transform: translate3d(100%, 0px, 0);
  }
}

@media screen and (min-width: 1025px) {
  .page-backward-enter {
    transform: translate3d(0px, 0, 0);
  }

  .page-backward-enter-active {
    transform: translate3d(0%, 0, 0);
  }

  .page-backward-exit {
    transform: translate3d(0%, 0px, 0);
    opacity: 1;
  }

  .page-backward-exit-active {
    transform: translate3d(100px, 0px, 0);
    opacity: 0;
    transition: transform 320ms ease, opacity 320ms ease;
    transition-delay: 50ms;
  }
}
